import { loadStripe } from "@stripe/stripe-js/pure";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { LocalFile } from "./types";
import { $currency, $currencyRate, $currencySymbol, $productAddToCart, $updateCartId, fastUpdateProductQuantity, updateCartStore } from "./store";
import { useStore } from "@nanostores/react";
import { useOptions } from "../hooks/useOptions";
// import { $productPrice } from "./store";
// import { useStore } from "@nanostores/react";

// prettier-ignore
export const STRIPE_URL = "https://test-stripe-backend-production.up.railway.app";
export const WP_API = "https://admin.fylore.com/wp-json/gatsby/v1";

// TODO: replace for production
// export const SITE_URL = "http://localhost:8000";
export const SITE_URL = "https://fylore.com";
// prettier-ignore
// export const REMOTE_TEST_URL = "https://d7ef-77-92-253-237.ngrok-free.app";

export const PRODUCT_PRICE_IN_CENTS = 3999;

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// export const stripePromise = loadStripe(
//   "pk_test_51KdxaBHq78ydJGCnitjdvZlKGKk7KmY2vYwDS8uTJ97izgzlvmrGL65derJQpCMrZQCxB9N03IYK3STdntCOZy2s00BxC9s9rL"
// );

// console.time("stripe load");
// PRODUCTION CONFIG

export const stripeLoader = () =>
  loadStripe(
    // "pk_test_51ObhpiJq8jxIENeZW9j2qs7oOyvUHXziO78QjaHNHmaxgJsF2kCPiycX4soTONR8GpW0cf3Iblt0gSAv7hDowaWQ001W9xN7rp" TEST MAIN
    "pk_live_51ObhpiJq8jxIENeZHBVMqWpDft640fA5Aami8fONMSjaBpgCdCsAAU4rjLnWYeDhWMYrLUAcA2hKDzjAA37i1ZFY00TqoQVVbo"
    // "pk_live_51NNy06Kjw5xZIMAqcC2xpaMpw66x7mLJ1ceYM5livf2xHD2Gd43NWcMGcsMdLei4NvrAwaVrFwMlLNVrFSTq3mF200kTXkWzXf"
  );

// console.timeEnd("stripe load");

export const STRIPE_OPTIONS = {
  mode: "payment",
  amount: PRODUCT_PRICE_IN_CENTS,
  currency: "usd",
  locale: "en",
  paymentMethodCreation: "manual",
  appearance: {
    theme: "stripe",
    rules: {
      ".Input::placeholder": {
        color: "var(--colorText)",
      },
      // ".Label": {
      //   opacity: "0",
      // },
    },
    variables: {
      colorBackground: "#36363D",
      colorText: "#D5D8DC",
      fontFamily: "'Red Hat Display', sans-serif",
      borderRadius: "2px",
      colorIconCardCvc: "#00000000",
    },
  },
} as const;

// TEST CONFIG
// export const PAYPAL_OPTIONS = {
//   "client-id":
//     "AQYHiuOVpJWttR8ypJsvnJq-fpZC2U9xcXu8V4cykLLuwPny_FygnzuHgy5D6XGo5Z_lIu5mqtvdV9fl",
//     // EO6QzrZyQeP5OnPOfsLKBWGHrANxNSmV-bkxeJm961g3XwzprususxjuFW3l2wFina-R_CZEGR5zO75Z
//   currency: "USD",
//   "disable-funding": "card",
// };

// PRODUCTION CONFIG
export const PAYPAL_OPTIONS = {
  "client-id":
    // "AS5f1tEpWIzWeRfW-pt0DJUL-vhnwUVgXHyMjCtGSDesapkuxX_u8_WXy2xJGJX2qEbztv2hir3mhWC9",
    "ARqG5TJKuJImplqbC8zd-FNZQf9p-6DCei9A7XlEqVqz3rpK75KV0ppzJGYDf4Z1N9hgLiLQ1zzs892Y",
  "disable-funding": "card,p24",
};

//PAYPAL prod
// AWFo-iVoItqthQSHlBx9oV3gjAie5b1eI8ogEtl9uMb1kOOWDcYqDx2DD_QwdzqoOWIpa4JUHSDlOWT0
// EG_2vB0nP3nxPPfAi9jI2QH5RCc_XH1CYiW6X0tYIHcj_zzjlasQ3W3wVM13Mz8Zc7_19gQdx48dCPHP


export const decodeHtmlCharCodes = (str: string): string => {
  return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
    return String.fromCharCode(charCode);
  });
};

const getSessionCookieAsURI = () =>
  encodeURIComponent(localStorage.getItem("session_cookie") || "");

export const getFrontPageData = async () => {
  const response = await fetch(`${WP_API}/pages?page=2`);
  const data = await response.json();

  return data;
};

export const createPaymentIntent: () => Promise<string> = async () => {
  const response = await fetch(`${STRIPE_URL}/create-payment-intent`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ items: [{ id: "fylore" }] }),
  });
  const data = await response.json();
  const { clientSecret }: { clientSecret: string } = data;

  return clientSecret;
};

export const createCart = async (body) => {
  let updateCartId = $updateCartId.get() + 1;
  $updateCartId.set(updateCartId);
  const currency = $currency.get();

  const response = await fetch(`${WP_API}/add_cart?session_cookie=${getSessionCookieAsURI()}&currency=${currency}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  updateCartStore(data, updateCartId);

  if (data?.session_cookie)
    localStorage.setItem("session_cookie", data?.session_cookie);

  $productAddToCart.set(false);
  return data;
};

export const getCart = async (is_first = true) => {
  const currency = localStorage.getItem("currency")?.replaceAll('"', '');

  try {
    const response = await fetch(
      `${WP_API}/get_cart?session_cookie=${getSessionCookieAsURI()}&currency=${currency}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log('getCart error');
    console.log(error);
    if (is_first) {
      return getCart(false);
    }
  }

  return null;
};

export const getPrice = async () => {
  const response = await fetch(`${WP_API}/products/12`);
  const data = await response.json();
  return data;
};

type PaymentMethod = "stripe" | "ppcp-gateway";

export const completePurchase = async (
  body: Record<string, unknown>
): Promise<any> => {
  const response = await fetch(
    `${WP_API}/checkout?session_cookie=${getSessionCookieAsURI()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }
  );
  const data = await response.json();

  if (data.session_cookie) {
    let updateCartId = $updateCartId.get() + 1;
    $updateCartId.set(updateCartId);
    updateCartStore(data, updateCartId);
    return {
      messages: data.messages
    }
  }

  return data;
};

type Subpath = "paypal" | "paypal-approve";

export const getNonce = async (subpath: Subpath) => {
  const response = await fetch(`${WP_API}/${subpath}/nonce`);
  const data = await response.json();

  return data;
};

export const getOrderDetails = async (key: string, orderId: string) => {
  const response = await fetch(
    `${WP_API}/orders/${orderId}?key=${encodeURIComponent(key)}`
  );
  const data = await response.json();

  return data;
};

// prettier-ignore
export const createOrderPaypal = async (body: Record<string, unknown>) => {
  const response = await fetch(
    `${WP_API}/paypal?session_cookie=${getSessionCookieAsURI()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...body,
      }),
    }
  );
  const data = await response.json();

  return data;
};

// prettier-ignore
export const onApprovePaypal = async (body: Record<string, unknown>) => {
  const response = await fetch(
    `${WP_API}/paypal-approve?session_cookie=${getSessionCookieAsURI()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...body,
      }),
    }
  );
  const data = await response.json();

  return data;
};

// prettier-ignore
export const onConfirmExpressPayment = async (body: Record<string, unknown>) => {
  const response = await fetch(
    // `${WP_API}/paypal?session_cookie=${getSessionCookieAsURI()}`,
    `${WP_API}/stripe-source?session_cookie=${getSessionCookieAsURI()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }
  );
  const data = await response.json();

  return data;
};

// prettier-ignore
export const validateCoupon = async (coupon: string) => {
  const response = await fetch(
    `${WP_API}/coupon?session_cookie=${getSessionCookieAsURI()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        coupon,
      }),
    }
  );
  const data = await response.json();

  return data;
};

export const updateCart = async (body: UpdateCardBody) => {
  let updateCartId = $updateCartId.get() + 1;
  $updateCartId.set(updateCartId);

  const response = await fetch(
    `${WP_API}/update_cart?session_cookie=${getSessionCookieAsURI()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }
  );
  const data = await response.json();

  updateCartStore(data, updateCartId);
  return data;
};

export const updateProductQuantity: (
  key: string,
  quantity: number
) => Promise<void> = async (key, quantity) => {
  const body = {
    cart: {
      [key]: quantity,
    },
  };

  fastUpdateProductQuantity(key, quantity);

  await updateCart(body);
};

export const getAddressFromIP = async () => {
  try {
    const response = await fetch(`https://ipapi.co/json/`);
    const data = await response.json();

    const address = {
      country: data.country,
      postal_code: data.postal_code || "",
      state: "",
      city: data.city || "",
      line1: "",
      line2: "",
    };

    return address;
  } catch (error) {
    console.log(
      "Could not retrieve user's address information, using default address"
    );

    const defaultAddress = {
      country: "US",
      postal_code: "",
      state: "",
      city: "",
      line1: "",
      line2: "",
    };

    return defaultAddress;
  }
};

export const getAddressData = (
  target: EventTarget & HTMLFormElement,
  prefix_option: "billing" | "shipping",
  prefix_form: "billing" | "shipping"
) => {
  const addressData = {
    [`${prefix_option}_first_name`]: target[`${prefix_form}_first_name`].value,
    [`${prefix_option}_last_name`]: target[`${prefix_form}_last_name`].value,
    [`${prefix_option}_phone`]: target[`${prefix_form}_phone`].value,
    [`${prefix_option}_country`]: target[`${prefix_form}_country`].value,
    [`${prefix_option}_city`]: target[`${prefix_form}_city`].value,
    [`${prefix_option}_address_1`]: target[`${prefix_form}_address_1`].value,
    [`${prefix_option}_address_2`]: target[`${prefix_form}_address_2`].value,
    [`${prefix_option}_postcode`]: target[`${prefix_form}_postcode`].value,
  };

  const potentialStateField = target[`${prefix_form}_state`];
  if (potentialStateField) {
    addressData[`${prefix_option}_state`] = potentialStateField.value;
  }
  return addressData;
};

// In case we use the same billing address, copy it from the Shipping Info store
export const copyShippingToBilling = (
  shippingInfo: any,
  billingAddress: any
) => {
  const result = { ...billingAddress };

  for (const [key, value] of Object.entries(shippingInfo)) {
    if (key in ["order_comments", "billing_email"]) continue;

    const newKey = key.replace("shipping", "billing");
    result[newKey] = value;
  }

  return result;
};

export const getGatsbyImageData: (image: LocalFile) => IGatsbyImageData | null = (
  image
) => {
  return image?.childrenImageSharp?.length
    ? image?.childrenImageSharp[0]?.gatsbyImageData
    : null;
};

export const removeCoupon = async (coupon: string) => {
  const response = await fetch(
    `${WP_API}/coupon/remove?session_cookie=${getSessionCookieAsURI()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        coupon,
      }),
    }
  );
  const data = await response.json();

  return data;
};

// prettier-ignore
export const log = async (source: string, info: string, debug: any | null) => {

  try {
    fetch(
      `${WP_API}/log`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          source, info, debug: { ...debug }
        }),
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const expressPurchaseError = async (
  order_id: number,
  key: string
): Promise<any> => {
  if (order_id) {
    fetch(
      `${WP_API}/orders/${order_id}/failed?session_cookie=${getSessionCookieAsURI()}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ key }),
      }
    );
  }
};

export const sendEvent = async (
  event: string,
  body: Record<string, unknown>
): Promise<any> => {
  const response = await fetch(
    `${WP_API}/ajax/${event}?session_cookie=${getSessionCookieAsURI()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }
  );
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return await response.json();
  }

  return await response.text();
};

export const getCurrency = () => {
  if (process.env.GATSBY_MULTICURRENCY) {
    return useStore($currency);
  } else {
    const options = useOptions();
    return options.wpOptions.data.currency
  }
};

export const getCurrencySymbol = () => {
  if (process.env.GATSBY_MULTICURRENCY) {
    return $currencySymbol.get();
  } else {
    const options = useOptions();
    return options.wpOptions.data.currency_symbol
  }
};

export const getCurrencyRate = () => {
  if (process.env.GATSBY_MULTICURRENCY) {
    return $currencyRate.get();
  } else {
    return 1
  }
};

export const printPrice = (price: number) => {
  const options = useOptions().wpOptions.data;

  const decimals = options.decimals ? options.decimals : 0;
  return Number(price).toFixed(decimals);
};

export const calculatePrice = (price: number) => {
  const currentRate = getCurrencyRate();
  return (Number(price) * Number(currentRate));
};

export const calculateOrPrint = (fixedPrice, originalPrice) => {
  return fixedPrice ? fixedPrice : calculatePrice(originalPrice);
};

export const getPrices = (data, currency) => {
  const { regular_price, sale_price, multi_regular_price, multi_sale_price } = data;

  const fixedSalePrice = multi_sale_price?.[currency];
  const fixedRegPrice = multi_regular_price?.[currency];

  return {
    regular_price: calculateOrPrint(fixedRegPrice, regular_price),
    sale_price: calculateOrPrint(fixedSalePrice, sale_price)
  };
};
