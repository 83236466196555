import "./src/styles/global.css";

import { loadPrice } from "./src/utils/store";
loadPrice();

function initGTM() {
  if (window.isGTMLoaded) {
    return false;
  };
  window.isGTMLoaded = true;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.key = 'gtag-script';
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA}`;
  document.head.appendChild(script);
};

function loadGTM(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, loadGTM);
};

export const onRouteUpdate = () => {
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    fbq("track", "ViewContent");
  }
};

export const onInitialClientRender = () => {
  document.onreadystatechange = () => {
    if (document.readyState !== 'loading') {
      // setTimeout(initGTM, 1000);
      initGTM();
    }
  };

  document.addEventListener('scroll', loadGTM);
  document.addEventListener('mousemove', loadGTM);
  document.addEventListener('touchstart', loadGTM);
}
