import { graphql, useStaticQuery } from "gatsby";
import { SiteOptions } from "../utils/optionsTypes";

export const useOptions: () => SiteOptions = () => {
  const data = useStaticQuery(graphql`
      query {
        wpOptions {
          data {
            currency
            currency_symbol
            multi_currency {
              name
              rate
              symbol
            }
            comments_open
            country_default
            page_title
            main_page {
              description
              title
            }
            hero_section {
              title
              description
              button
              images {
                title
                url
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(
                      breakpoints: [300, 380, 480, 600]
                      quality: 80
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            reasons_section {
              title
              description
              reasons {
                title
                image {
                  url
                  title
                }
              }
            }
            description_section {
              title
              image {
                title
                url
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(
                      breakpoints: [300, 380, 480, 550]
                      quality: 80
                      formats: [PNG]
                    )
                  }
                }
              }
              image_left {
                title
                url
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(
                      breakpoints: [300, 380]
                      quality: 80
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              image_right {
                title
                url
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(
                      breakpoints: [300, 380]
                      quality: 80
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              items {
                title
                description
              }
            }
            product_card {
              title
              button
              benefits {
                title
                image {
                  title
                  url
                }
              }
              images {
                title
                url
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(
                      breakpoints: [300, 380, 480, 700]
                      quality: 80
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              tabs {
                description
                title
              }
              button_title
            }
            options_section {
              title
              description
              images {
                url
                title
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(
                      breakpoints: [300, 380, 400]
                      quality: 80
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              options {
                title
                description
              }
            }
            results_section {
              title
              disclaimer
              items {
                title
                subtitle
                review
                author
                role
                percent
                label_after
                label_before
                image_after {
                  title
                  url
                  localFile {
                    childrenImageSharp {
                      gatsbyImageData(
                        breakpoints: [300, 380]
                        width: 500
                        quality: 80
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                image_before {
                  title
                  url
                  localFile {
                    childrenImageSharp {
                      gatsbyImageData(
                        breakpoints: [300, 380]
                        width: 500
                        quality: 80
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            banner_section {
              image {
                title
                url
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(
                      breakpoints: [300, 380, 480, 660]
                      quality: 80
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            how_to_section {
              title
              description
              steps {
                title
              }
              images {
                title
                url
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(
                      breakpoints: [300, 380, 480, 660]
                      quality: 80
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            reviews_section {
              title
              videos {
                name
                url
              }
              reviews {
                title
                review
                author
                role
                image {
                  title
                  url
                  localFile {
                    childrenImageSharp {
                      gatsbyImageData(
                        breakpoints: [300, 380, 480, 660]
                        quality: 80
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            benefits_section {
              title
              description
              benefits {
                title
                description
                image {
                  title
                  url
                }
              }
            }
            subscribe_section {
              title
              image {
                title
                url
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(
                      breakpoints: [300, 380, 480, 660]
                      quality: 80
                        formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              name_input_label
              email_input_label
              checkbox_label
              button
            }
            faq_section {
              title
              description
              faq_items {
                answer
                question
              }
            }
          }
        }
      }
    `);

  return data;
};